import { Client, CustomFieldSelection, Household, CustomField as HouseholdCustomField } from '../entities/household';
import { ensureHasValue, isNullOrUndefined, replaceOrAdd } from '../shared/util';
import { ContactDetailsService } from './contact-details.service';
import { CustomField } from '../enums/custom-field';
import { CustomFieldService } from './custom-field.service';
import { CustomValueService } from './custom-value.service';
import { CustomValues } from '../enums/custom-value';
import { EmploymentDetailsService } from './employment-details.service';
import { EstatePlanningDetailsService } from './estate-planning-details.service';
import { HealthInsuranceService } from './health-insurance.service';
import { HouseholdService } from './household.service';
import { Injectable } from '@angular/core';
import { InsurancePreassessmentQuestionService } from './insurance-preassessment-question.service';
import { InsurancePreassessmentQuestions } from '../enums/insurance-preassessment-question';
import { JourneyClient } from '../entities/journey-client';
import { JourneyClientService } from './journey-client.service';
import { JourneyDependantService } from './journey-dependant.service';
import { JourneyExcludedAdviceAreaService } from './journey-excluded-advice-area.service';
import { JourneyHoldingService } from './journey-holding-service';
import { JourneyInsuranceService } from './journey-insurance-service';
import { JourneyLiabilityService } from './journey-liability-service';
import { JourneyMilestoneService } from './journey-milestone.service';
import { JourneyObjectiveService } from './journey-objective.service';
import { JourneyPaymentService } from './journey-payment-service';
import { JourneyPaymentType } from '../enums/journey-payment-type';
import { JourneyPersonalAssetService } from './journey-personal-asset-service';
import { JourneyService } from './journey.service';
import { MasterDataService } from './master-data.service';
import { PrivacyQuestionService } from './privacy-question.service';
import { PrivacyQuestions } from '../enums/privacy-question';
import { SessionTypes } from '../enums/session-type';
import { SocialSecurityService } from './social-security.service';
import { WellnessScoreService } from './wellness-score.service';
import { firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ActionsService {
  constructor(
    private householdService: HouseholdService,
    private journeyService: JourneyService,
    private journeyClientService: JourneyClientService,
    private wellnessScoreService: WellnessScoreService,
    private privacyQuestionService: PrivacyQuestionService,
    private contactDetailsService: ContactDetailsService,
    private journeyHoldingService: JourneyHoldingService,
    private journeyLiabilityService: JourneyLiabilityService,
    private journeyPersonalAssetService: JourneyPersonalAssetService,
    private socialSecurityService: SocialSecurityService,
    private customFieldService: CustomFieldService,
    private healthInsuranceService: HealthInsuranceService,
    private employmentDetailsService: EmploymentDetailsService,
    private estatePlanningDetailsService: EstatePlanningDetailsService,
    private journeyDependantService: JourneyDependantService,
    private journeyInsuranceService: JourneyInsuranceService,
    private journeyObjectiveService: JourneyObjectiveService,
    private journeyPaymentService: JourneyPaymentService,
    private journeyExcludedAdviceAreaService: JourneyExcludedAdviceAreaService,
    private journeyMilestoneService: JourneyMilestoneService,
    private customValueService: CustomValueService,
    private insurancePreassessmentQuestionService: InsurancePreassessmentQuestionService
  ) { }

  async refreshDataFromAOS(journeyID: string): Promise<void> {
    if (!this.householdService.household) return;

    await this.journeyClientService.refresh(journeyID);
    await Promise.all([
      this.wellnessScoreService.refresh(),
      this.privacyQuestionService.refresh(journeyID),
      this.contactDetailsService.refresh(journeyID),
      this.journeyHoldingService.refresh(),
      this.journeyLiabilityService.refresh(),
      this.journeyPersonalAssetService.refresh(),
      this.socialSecurityService.refresh(journeyID),
      this.healthInsuranceService.refresh(journeyID),
      this.employmentDetailsService.refresh(journeyID),
      this.estatePlanningDetailsService.refresh(journeyID),
      this.journeyDependantService.refresh(),
      this.journeyInsuranceService.refresh(),
      this.journeyObjectiveService.refresh(),
      this.journeyPaymentService.refresh(),
      this.journeyExcludedAdviceAreaService.refresh(),
      this.journeyMilestoneService.refresh(),
      this.insurancePreassessmentQuestionService.refresh()
    ]);

    await this.journeyService.updateRefreshedFromAOS(true);
  }

  async saveDataToAOS(): Promise<void> {
    if (!this.householdService.household) return;

    if (!this.journeyService.getNonNullableJourney().journeyDataSuccessfullyRefreshed) {
      throw new Error("The journey did not load successfully. Therefore, it is invalid and you cannot publish it.");
    }

    this.householdService.household = await firstValueFrom(this.householdService.getHousehold(this.householdService.household.client.clientID));

    if (this.journeyClientService.primaryJourneyClient()) {
      if (this.journeyClientService.primaryJourneyClient()?.maritalStatusID) this.householdService.household.maritalStatusID = Number(this.journeyClientService.primaryJourneyClient()?.maritalStatusID);

      await Promise.all([
        this.updateClient(this.householdService.household.client, this.journeyClientService.primaryJourneyClient()),
        this.updateJourneyClientContactDetails(this.householdService.household.client, this.journeyClientService.primaryJourneyClient(), true),
        this.updateSocialSecurity(this.householdService.household.client, true),
        this.updateHealthInsurances(this.householdService.household.client),
        this.updateEmploymentDetails(this.householdService.household.client),
        this.updateEstatePlanningDetails(this.householdService.household.client, true)
      ]);

      this.updateMedicalInfo(this.householdService.household.client);
    }

    if (this.householdService.household.spouse && this.journeyClientService.spouseJourneyClient()) {
      await Promise.all([
        this.updateClient(this.householdService.household.spouse, this.journeyClientService.spouseJourneyClient()),
        this.updateJourneyClientContactDetails(this.householdService.household.spouse, this.journeyClientService.spouseJourneyClient()),
        this.updateSocialSecurity(this.householdService.household.spouse, false),
        this.updateHealthInsurances(this.householdService.household.spouse),
        this.updateEmploymentDetails(this.householdService.household.spouse),
        this.updateEstatePlanningDetails(this.householdService.household.spouse, false)
      ]);

      this.updateMedicalInfo(this.householdService.household.spouse);
    }

    await Promise.all([
      this.updateHoldings(this.householdService.household),
      this.updateLiabilities(this.householdService.household),
      this.updatePersonalAssets(this.householdService.household),
      this.updateInsurance(this.householdService.household),
      this.updatePayments(this.householdService.household),
      this.updateHouseholdCustomFields(this.householdService.household),
      this.updateHouseholdCustomFieldSelections(this.householdService.household)
    ]);

    this.journeyDependantService.updateDependants();
    await this.journeyObjectiveService.updateJourneyObjectivesToAos();

    await firstValueFrom(this.householdService.saveHousehold(this.householdService.household, this.journeyService.getNonNullableJourney()));

    await this.journeyService.updateSuccessfullySavedToAOS(true);
  }

  async loadJourneyData(previousJourneyInstanceID: string): Promise<void> {
    await this.journeyClientService.loadJourneyClients(this.journeyService.getNonNullableJourney().journeyID);

    await Promise.all([
      this.journeyHoldingService.loadJourneyHoldings(previousJourneyInstanceID),
      this.journeyLiabilityService.loadJourneyLiabilities(previousJourneyInstanceID),
      this.journeyPersonalAssetService.loadJourneyPersonalAssets(previousJourneyInstanceID),
      this.journeyDependantService.loadJourneyDependants(previousJourneyInstanceID),
      this.journeyInsuranceService.loadJourneyInsurances(previousJourneyInstanceID),
      this.journeyObjectiveService.loadJourneyObjectives(previousJourneyInstanceID),
      this.journeyPaymentService.loadJourneyPayments(previousJourneyInstanceID),
      this.journeyExcludedAdviceAreaService.refresh(),
      this.journeyMilestoneService.refresh(),
      this.insurancePreassessmentQuestionService.loadInsurancePreassessmentQuestions()
    ]);

    if (this.journeyService.getNonNullableJourney().sessionType !== SessionTypes.AdviserNotes.toString()) {
      await this.privacyQuestionService.refreshIDVerificationQuestion(this.journeyService.getNonNullableJourney().journeyID);
    }
  }

  private async updateClient(client: Client, journeyClient: JourneyClient | null): Promise<void> {
    if (!journeyClient) return;

    ActionsService.updateClientPersonalDetails(client, journeyClient);
    ActionsService.updateClientSalutations(client, journeyClient);
    ActionsService.updateClientAdditionalDetails(client, journeyClient);
    await this.updateSelectionCustomFields(client);
    await this.updatePrivacyConsent(client);
  }

  private async updateSelectionCustomFields(client: Client): Promise<void> {
    if (!this.journeyService.journey) return;

    const wellnessScores = await firstValueFrom(this.wellnessScoreService.getWellnessScores(this.journeyService.journey.journeyID));
    const clientWellnessScores = wellnessScores.filter(x => x.journeyClientID === client.clientID);

    for (const question of clientWellnessScores) {
      const selectionCustomField = client.customFieldSelections.find(x => x.customFieldID === question.questionID);

      const score = question.score;

      if (isNullOrUndefined(score)) {
        // If the score is null, then leave the custom field selection as is.
        continue;
      }

      if (selectionCustomField) {
        selectionCustomField.selectedFieldItems = [{ itemID: null, itemName: score.toString() }]
        continue;
      }

      client.customFieldSelections.push({
        customFieldID: question.questionID,
        selectedFieldItems: [{ itemID: null, itemName: score.toString() }]
      });
    }
  }

  private async updatePrivacyConsent(client: Client): Promise<void> {
    if (!this.journeyService.journey) return;

    const privacyConsentQuestions = (await firstValueFrom(this.privacyQuestionService.getPrivacyQuestions(this.journeyService.journey.journeyID))).filter(x => x.journeyClientID === client.clientID);

    client.privacyConsent = privacyConsentQuestions.find(x => x.questionID === Number(PrivacyQuestions.PrivacyConsent))?.answer;
    client.electronicConsent = privacyConsentQuestions.find(x => x.questionID === Number(PrivacyQuestions.ElectronicConsent))?.answer;
    client.marketingConsent = privacyConsentQuestions.find(x => x.questionID === Number(PrivacyQuestions.MarketingConsent))?.answer;
  }

  private static updateClientPersonalDetails(client: Client, journeyClient: JourneyClient): void {
    if (journeyClient.firstName) client.firstName = journeyClient.firstName;
    if (journeyClient.lastName) client.lastName = journeyClient.lastName;
    if (journeyClient.middleName) client.middleName = journeyClient.middleName;
    if (journeyClient.preferredName) client.preferredName = journeyClient.preferredName;
    if (journeyClient.gender) client.gender = journeyClient.gender;
    if (journeyClient.birthDate) client.birthDate = journeyClient.birthDate;

  }

  private static updateClientSalutations(client: Client, journeyClient: JourneyClient) {
    if (journeyClient.salutationID) client.salutationID = journeyClient.salutationID;

    if (journeyClient.salutationID === MasterDataService.getSalutations().find(x => x.name === "Other")?.iD && !isNullOrUndefined(journeyClient.salutationOther)) {
      client.salutationOtherText = journeyClient.salutationOther;
    } else {
      client.salutationOtherText = null;
    }
  }

  private static updateClientAdditionalDetails(client: Client, journeyClient: JourneyClient) {
    if (journeyClient.residencyStatusID) client.residencyStatusID = journeyClient.residencyStatusID;
    if (journeyClient.retirementAge) client.retirementAge = journeyClient.retirementAge;
  }

  private async updateJourneyClientContactDetails(client: Client, journeyClient: JourneyClient | null, updateAddress = false): Promise<void> {
    if (!journeyClient?.journeyID) return;

    const contactDetails = await firstValueFrom(this.contactDetailsService.getLatestClientContactDetails(journeyClient.journeyID, client.clientID));

    if (!contactDetails) return;

    client.homePhone = contactDetails.homePhone;
    client.workPhone = contactDetails.workPhone;
    client.mobilePhone = contactDetails.mobilePhone;
    client.email = contactDetails.email;

    if (updateAddress) {
      if (this.householdService.household && !this.householdService.household.residentialAddress) {
        this.householdService.household.residentialAddress = {};
      }

      if (this.householdService.household?.residentialAddress) {
        this.householdService.household.residentialAddress = {
          address1: contactDetails.address1,
          address2: contactDetails.address2,
          postCode: contactDetails.postCode,
          stateID: contactDetails.stateID,
          suburb: contactDetails.suburb
        };
      }
    }
  }

  private async updateHoldings(household: Household) {
    await firstValueFrom(this.journeyHoldingService.refreshFromApi());
    household.holdings = this.journeyHoldingService.mapJourneyToDtoForOwner("joint");
    household.client.holdings = this.journeyHoldingService.mapJourneyToDtoForOwner("primary");
    if (household.spouse) {
      household.spouse.holdings = this.journeyHoldingService.mapJourneyToDtoForOwner("spouse");
    }
  }

  private async updateLiabilities(household: Household) {
    await firstValueFrom(this.journeyLiabilityService.refreshFromApi());
    household.liabilities = this.journeyLiabilityService.mapJourneyToDtoForOwner("joint");
    household.client.liabilities = this.journeyLiabilityService.mapJourneyToDtoForOwner("primary");
    if (household.spouse) {
      household.spouse.liabilities = this.journeyLiabilityService.mapJourneyToDtoForOwner("spouse");
    }
  }

  private async updatePersonalAssets(household: Household) {
    await firstValueFrom(this.journeyPersonalAssetService.refreshFromApi());
    household.personalAssets = this.journeyPersonalAssetService.mapJourneyToDtoForOwner("joint");
    household.client.personalAssets = this.journeyPersonalAssetService.mapJourneyToDtoForOwner("primary");
    if (household.spouse) {
      household.spouse.personalAssets = this.journeyPersonalAssetService.mapJourneyToDtoForOwner("spouse");
    }
  }

  private async updateSocialSecurity(client: Client, isPrimary: boolean): Promise<void> {
    if (!this.journeyService.journey?.journeyID) return;

    const clientSocialSecurity = await firstValueFrom(this.socialSecurityService.getLatestClientSocialSecurity(this.journeyService.journey.journeyID, client.clientID));

    if (!clientSocialSecurity) return;

    if (!isNullOrUndefined(clientSocialSecurity.hasSeniorHealthCareCard)) {
      const hasSeniorHealthCareCard = this.customFieldService.getCustomField(CustomField.HasSeniorHealthCareCard, isPrimary);
      if (hasSeniorHealthCareCard) hasSeniorHealthCareCard.value = this.socialSecurityService.convertBooleanToCustomFieldValueString(clientSocialSecurity.hasSeniorHealthCareCard);
    }

    if (!isNullOrUndefined(clientSocialSecurity.providesCaretoAnotherSick)) {
      const providesCareToAnotherSick = this.customFieldService.getCustomField(CustomField.ProvidesCareToAnotherSick, isPrimary);
      if (providesCareToAnotherSick) providesCareToAnotherSick.value = this.socialSecurityService.convertBooleanToCustomFieldValueString(clientSocialSecurity.providesCaretoAnotherSick);
    }

    client.mainCentrelinkBenefitTypeId = clientSocialSecurity.mainCentrelinkBenefitTypeId;
  }

  private async updateHealthInsurances(client: Client): Promise<void> {
    if (!this.journeyService.journey?.journeyID) return;

    const clientHealthInsurance = await firstValueFrom(this.healthInsuranceService.getLatestClientHealthInsurance(this.journeyService.journey.journeyID, client.clientID));

    if (!clientHealthInsurance) return;

    client.hasPrivateHealthInsurance = clientHealthInsurance.hasPrivateHealthInsurance;
    client.privateHealthFund = clientHealthInsurance.privateHealthFund;
  }

  private async updateEmploymentDetails(client: Client): Promise<void> {
    if (!this.journeyService.journey?.journeyID) return;

    const clientEmploymentDetails = await firstValueFrom(this.employmentDetailsService.getLatestClientEmploymentDetail(this.journeyService.journey.journeyID, client.clientID));

    if (!clientEmploymentDetails) return;

    client.employer = clientEmploymentDetails.employer;
    client.occupation = clientEmploymentDetails.occupation;
    client.employmentTypeId = clientEmploymentDetails.employmentTypeId;
    client.hoursWorkedPerWeek = clientEmploymentDetails.hoursWorkedPerWeek;
    client.superGuaranteePercentage = clientEmploymentDetails.superGuaranteePercentage;
    client.startOfEmployment = clientEmploymentDetails.startOfEmployment;
    client.annualLeaveDays = clientEmploymentDetails.annualLeaveDays;
    client.sickLeaveDays = clientEmploymentDetails.sickLeaveDays;
    client.longServiceLeaveDays = clientEmploymentDetails.longServiceLeaveDays;
  }

  private async updateEstatePlanningDetails(client: Client, isPrimary: boolean): Promise<void> {
    if (!this.journeyService.journey?.journeyID) return;

    const clientEstatePlanningDetails = await firstValueFrom(this.estatePlanningDetailsService.getLatestClientEstatePlanningDetails(this.journeyService.journey.journeyID, client.clientID));

    if (!clientEstatePlanningDetails) return;

    const doYouHaveTestamentaryTrust = this.customFieldService.getCustomField(CustomField.DoYouHaveTestamentaryTrust, isPrimary);
    if (doYouHaveTestamentaryTrust) doYouHaveTestamentaryTrust.value = String(clientEstatePlanningDetails.hasTestamentaryTrust);

    client.hasWill = clientEstatePlanningDetails.hasWill;
    client.dateOfWill = clientEstatePlanningDetails.dateOfWill;
    client.hasEnduringPowerOfAttorney = clientEstatePlanningDetails.hasEnduringPowerOfAttorney;
    client.hasGeneralPowerOfAttorney = clientEstatePlanningDetails.hasGeneralPowerOfAttorney;
    client.hasMedicalPowerOfAttorney = clientEstatePlanningDetails.hasMedicalPowerOfAttorney
  }

  private async updateInsurance(household: Household) {
    if (!this.journeyInsuranceService.dataInitialised) {
      return
    }

    const householdInsurances = await this.journeyInsuranceService.mapJourneyToHousehold()
    household.insurances = householdInsurances.joint;
    household.client.insurances = householdInsurances.primary;
    if (household.spouse) {
      household.spouse.insurances = householdInsurances.spouse;
    }
  }

  private async updatePayments(household: Household) {
    await firstValueFrom(this.journeyPaymentService.refreshFromApi());
    const incomes = this.journeyPaymentService.mapJourneyToHouseholdForJourneyPaymentType(JourneyPaymentType.Income);
    const expenses = this.journeyPaymentService.mapJourneyToHouseholdForJourneyPaymentType(JourneyPaymentType.Expense);

    household.incomes = incomes.joint;
    household.expenses = expenses.joint;

    household.client.incomes = incomes.primary;
    household.client.expenses = expenses.primary;

    if (household.spouse) {
      household.spouse.incomes = incomes.spouse;
      household.spouse.expenses = expenses.spouse;
    }
  }

  private async updateHouseholdCustomFields(household: Household) {
    await this.updateNewAdviceFeeCustomFields(household.householdCustomFields);
  }

  private async updateNewAdviceFeeCustomFields(customFields: HouseholdCustomField[]): Promise<void> {
    const data = await Promise.all([
      firstValueFrom(this.customValueService.getCustomValue(String(CustomValues.AdviceFeeDiscountAmount))),
      firstValueFrom(this.customValueService.getCustomValue(String(CustomValues.AdviceFeeNetAmount))),
    ]);

    const adviceFeeDiscountAmountCustomValue = data.find(x => x && x.customValueID === String(CustomValues.AdviceFeeDiscountAmount));
    const adviceFeeNetAmountCustomValue = data.find(x => x && x.customValueID === String(CustomValues.AdviceFeeNetAmount));

    const newCustomFields: HouseholdCustomField[] = [];

    if (adviceFeeDiscountAmountCustomValue) {
      newCustomFields.push({
        value: ensureHasValue(adviceFeeDiscountAmountCustomValue.value, `CustomValueId: ${String(CustomValues.AdviceFeeDiscountAmount)}`),
        customFieldID: CustomField.AdviceFeeDiscountAmount
      })
    }

    if (adviceFeeNetAmountCustomValue) {
      newCustomFields.push({
        value: ensureHasValue(adviceFeeNetAmountCustomValue.value, `CustomValueId: ${String(CustomValues.AdviceFeeNetAmount)}`),
        customFieldID: CustomField.AdviceFeeNetAmount
      })
    }

    replaceOrAdd(customFields, (a, b) => a.customFieldID === b.customFieldID, newCustomFields);
  }

  private async updateHouseholdCustomFieldSelections(household: Household) {
    await this.getNewAdviceFeeCustomFieldSelections(household.householdCustomFieldSelections);
  }

  private async getNewAdviceFeeCustomFieldSelections(customFieldSelections: CustomFieldSelection[]): Promise<void> {
    const data = await Promise.all([
      firstValueFrom(this.customValueService.getCustomValue(String(CustomValues.AdviceFeeTierId))),
    ]);

    const adviceFeeTierIdCustomValue = data.find(x => x && x.customValueID === String(CustomValues.AdviceFeeTierId));

    const newCustomFieldSelections: CustomFieldSelection[] = [];

    if (adviceFeeTierIdCustomValue) {
      newCustomFieldSelections.push({
        selectedFieldItems: [{ itemID: Number(ensureHasValue(adviceFeeTierIdCustomValue.value)), itemName: null }],
        customFieldID: CustomField.AdviceFeeTier,
      });
    }

    replaceOrAdd(customFieldSelections, (a, b) => a.customFieldID === b.customFieldID, newCustomFieldSelections);
  }

  private updateMedicalInfo(client: Client) {
    const clientInsurancePreassessmentQuestions = this.insurancePreassessmentQuestionService.insurancePreassessmentQuestions().filter(x => x.journeyClientID === client.clientID);
    client.height = this.insurancePreassessmentQuestionService.stringToNumber(clientInsurancePreassessmentQuestions.find(x => x.questionID === Number(InsurancePreassessmentQuestions.Height))?.value);
    client.weight = this.insurancePreassessmentQuestionService.stringToNumber(clientInsurancePreassessmentQuestions.find(x => x.questionID === Number(InsurancePreassessmentQuestions.Weight))?.value);
    client.wasWeightLostLast12Months = this.insurancePreassessmentQuestionService.stringToBoolean(clientInsurancePreassessmentQuestions.find(x => x.questionID === Number(InsurancePreassessmentQuestions.HaveYouGainedLostMoreThanFiveKG))?.value);
    client.weightLossLast12MonthsDetails = clientInsurancePreassessmentQuestions.find(x => x.questionID === Number(InsurancePreassessmentQuestions.HaveYouGainedLostMoreThanFiveKG))?.comment;
    client.hasSmokedLast12Months = this.insurancePreassessmentQuestionService.stringToBoolean(clientInsurancePreassessmentQuestions.find(x => x.questionID === Number(InsurancePreassessmentQuestions.HaveYouSmokedAnySubstancesLastTwelveMonths))?.value);
    client.smokedLast12MonthsDetails = clientInsurancePreassessmentQuestions.find(x => x.questionID === Number(InsurancePreassessmentQuestions.HaveYouSmokedAnySubstancesLastTwelveMonths))?.comment;
    client.standardDrinksPerWeek = this.insurancePreassessmentQuestionService.stringToNumber(clientInsurancePreassessmentQuestions.find(x => x.questionID === Number(InsurancePreassessmentQuestions.HowManyStandardDrinksPerWeek))?.value);
    const healthID = this.insurancePreassessmentQuestionService.stringToNumber(clientInsurancePreassessmentQuestions.find(x => x.questionID === Number(InsurancePreassessmentQuestions.Health))?.value);

    if (healthID) {
      client.health = MasterDataService.getHealthTypes().find(x => x.iD === healthID)?.name;
    }
  }
}
